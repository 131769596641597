<template>
  <div>

    <!-- #region::Table -->
    <vue-good-table
      ref="pieces-table"
      class="vgt-table-checkbox-styled"
      :columns="columns"
      :rows="piecesList"
      style-class="vgt-table condensed vgt-checkbox-col"
      :line-numbers="true"
      :select-options="{
        enabled: selectable,
        selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
        selectionInfoClass: 'custom-class',
        selectionText: 'rows selected',
        clearSelectionText: 'clear',
        disableSelectInfo: true, // disable the select info panel on top
        selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
      }"
      :sort-options="{
        enabled: false,
      }"
      :pagination-options="{
        enabled: true,
        mode: 'records',
        perPage: 10,
        perPageDropdown: [3, 5, 10],
        dropdownAllowAll: true,
        jumpFirstOrLast : true,
        firstLabel : 'Primera página',
        lastLabel : 'Última página',
        nextLabel: 'Siguiente',
        prevLabel: 'Anterior',
        rowsPerPageLabel: 'Piezas por página',
        ofLabel: 'de',
        allLabel: 'Todo',
      }"
      @on-selected-rows-change="selectionChanged"
    >

      <!-- #region::Empty response -->
      <div slot="emptystate">
        This will show up when there are no rows
      </div>
      <!-- #endregion::Empty response -->

      <!-- #region::Column headers -->
      <template
        slot="table-column"
        slot-scope="props"
      >
        <span>
          <h6 class="capitalize-col-hd">
            <strong>{{ props.column.label }}</strong>
          </h6>
        </span>
      </template>
      <!-- #endregion::Column headers -->

      <!-- #region::Modifying rows cells -->
      <template
        slot="table-row"
        slot-scope="props"
      >

        <!-- #region::Tag cell -->
        <span v-if="props.column.field === 'Tag'">
          <div class="d-flex justify-content-start">
            <h6><text-highlight
              :queries="canHighlight"
              highlight-style="background-color: #ebd834;"
            >
              {{ props.row.Tag }}
            </text-highlight></h6>
            <div
              v-if="canShowTransferTag(props.row)"
              class="sm-banner-alert alert-pink"
              role="alert"
            >
              Traspaso
            </div>
          </div>
        </span>
        <!-- #endregion::Tag cell -->

        <!-- #region::NoSeries cell -->
        <span v-else-if="props.column.field === 'NoSeries'">
          <h6><text-highlight
            :queries="canHighlight"
            highlight-style="background-color: #ebd834;"
          >
            {{ props.row.NoSeries }}
          </text-highlight></h6>
        </span>
        <!-- #endregion::NoSeries cell -->

        <!-- #region::NoPetition cell -->
        <span v-else-if="props.column.field === 'NoPetition'">
          <h6><text-highlight
            :queries="canHighlight"
            highlight-style="background-color: #ebd834;"
          >
            {{ props.row.NoPetition }}
          </text-highlight></h6>
        </span>
        <!-- #endregion::NoPetition cell -->

        <!-- #region::NoInvoice cell -->
        <span v-else-if="props.column.field === 'NoInvoice'">
          <h6><text-highlight
            :queries="canHighlight"
            highlight-style="background-color: #ebd834;"
          >
            {{ props.row.NoInvoice }}
          </text-highlight></h6>
        </span>
        <!-- #endregion::NoInvoice cell -->

        <!-- #region::NoImport cell -->
        <span v-else-if="props.column.field === 'NoImport'">
          <h6><text-highlight
            :queries="canHighlight"
            highlight-style="background-color: #ebd834;"
          >
            {{ props.row.NoImport }}
          </text-highlight></h6>
        </span>
        <!-- #endregion::NoImport cell -->

        <!-- #region::Condition cell -->
        <span v-else-if="props.column.field === 'Condition'">
          <h6><text-highlight
            :queries="canHighlight"
            highlight-style="background-color: #ebd834;"
          >
            {{ props.row.Condition }}
          </text-highlight></h6>
        </span>
        <!-- #endregion::Condition cell -->

        <!-- #region::Availability cell -->
        <span v-else-if="props.column.field === 'Availability'">
          <h6><b-badge
            class="ava-label"
            :variant="getAvailabilityStyle(props.row.Availability)"
          >
            <text-highlight
              :queries="canHighlight"
              highlight-style="background-color: #ebd834;"
            >{{ props.row.Availability }}
            </text-highlight></b-badge></h6>
        </span>
        <!-- #endregion::Availability cell -->

        <!-- #region::Location cell -->
        <span v-else-if="props.column.field === 'Location'">
          <h6><text-highlight
            :queries="canHighlight"
            highlight-style="background-color: #ebd834;"
          >
            {{ props.row.Location }}
          </text-highlight></h6>
        </span>
        <!-- #endregion::Location cell -->

        <!-- #region::Arrive date cell -->
        <span v-else-if="props.column.field === 'ArriveDate'">
          <h6 class="ava-label">
            <text-highlight
              :queries="canHighlight"
              highlight-style="background-color: #ebd834;"
            >
              {{ props.row.ArriveDate | moment("DD/MM/YYYY") }}
            </text-highlight></h6>
        </span>
        <!-- #endregion::Arrive date cell -->

        <!-- #region::UnitPriceInvoiceUsd cell -->
        <span v-else-if="props.column.field === 'UnitPriceInvoiceUsd'">
          <h6><text-highlight
            :queries="canHighlight"
            highlight-style="background-color: #ebd834;"
          >
            {{ props.row.UnitPriceInvoiceUsd | currencyWhite }}
          </text-highlight></h6>
        </span>
        <!-- #endregion::UnitPriceInvoiceUsd cell -->

        <!-- #region::ExchangeRate cell -->
        <span v-else-if="props.column.field === 'Tc'">
          <h6><text-highlight
            :queries="canHighlight"
            highlight-style="background-color: #ebd834;"
          >
            {{ props.row.Tc | currencyWhite }}
          </text-highlight></h6>
        </span>
        <!-- #endregion::ExchangeRate cell -->

        <!-- #region::UnitPriceInvoiceMn cell -->
        <span v-else-if="props.column.field === 'UnitPriceInvoiceMn'">
          <h6><text-highlight
            :queries="canHighlight"
            highlight-style="background-color: #ebd834;"
          >
            {{ props.row.UnitPriceInvoiceMn | currencyWhite }}
          </text-highlight></h6>
        </span>
        <!-- #endregion::UnitPriceInvoiceMn cell -->

        <!-- #region::Ccost cell -->
        <span v-else-if="props.column.field === 'Ccost'">
          <h6><text-highlight
            :queries="canHighlight"
            highlight-style="background-color: #ebd834;"
          >
            {{ props.row.Ccost | currencyWhite }}
          </text-highlight></h6>
        </span>
        <!-- #endregion::Ccost cell -->

        <!-- #region::ProvidersPriceUsd cell -->
        <span v-else-if="props.column.field === 'ProvidersPriceUsd'">
          <h6><text-highlight
            :queries="canHighlight"
            highlight-style="background-color: #ebd834;"
          >
            {{ props.row.ProvidersPriceUsd | currencyWhite }}
          </text-highlight></h6>
        </span>
        <!-- #endregion::ProvidersPriceUsd cell -->

        <!-- #region::ProvidersCostingMn cell -->
        <span v-else-if="props.column.field === 'ProvidersCostingMn'">
          <h6><text-highlight
            :queries="canHighlight"
            highlight-style="background-color: #ebd834;"
          >
            {{ props.row.ProvidersCostingMn | currencyWhite }}
          </text-highlight></h6>
        </span>
        <!-- #endregion::ProvidersCostingMn cell -->

        <!-- #region::Actions column -->
        <span v-else-if="props.column.field === 'actions'">

          <!-- #region::Delete piece button -->
          <b-button
            v-if="pieceInCircuit(props.row) || loadCircuitProducts"
            variant="flat-danger"
            size="sm"
            @click="deletePiece(props.row)"
          >
            <feather-icon
              icon="MinusIcon"
              class="mr-50"
            />
            <span class="align-middle font-weight-bolder">Eliminar</span>
          </b-button>
          <!-- #endregion::Delete piece button -->

          <!-- #region::Add piece button -->
          <b-button
            v-else
            variant="flat-primary"
            size="sm"
            @click="addPiece(props.row)"
          >
            <feather-icon
              icon="PlusIcon"
              class="mr-50"
            />
            <span class="align-middle font-weight-bolder">Agregar</span>
          </b-button>
          <!-- #endregion::Add piece button -->

        </span>
        <!-- #endregion::Actions column -->

        <!-- #region::Rest of columns -->
        <span v-else>
          <h6>{{ props.formattedRow[props.column.field] }}</h6>
        </span>
        <!-- #endregion::Rest of columns -->

      </template>
      <!-- #endregion::Modifying rows cells -->

    </vue-good-table>
    <!-- #endregion::Table -->

  </div>
</template>

<script>
// #region Imports
import { mapGetters, mapActions } from 'vuex'
import { BBadge, BButton } from 'bootstrap-vue'

import { VueGoodTable } from 'vue-good-table'
import TextHighlight from 'vue-text-highlight'
import 'vue-good-table/dist/vue-good-table.css'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
// #endregion

export default {
  name: 'PiecesList',
  components: {
    BBadge,
    BButton,
    VueGoodTable,
    TextHighlight,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
  },
  props: {
    pieces: {
      type: Array,
      required: true,
    },
    selectable: {
      type: Boolean,
      default: false,
    },
    product: {
      type: Object,
      default: null,
    },
    loadCircuitProducts: {
      type: Boolean,
      default: false,
    },
    // * Conditional columns
    showInvoiceColumn: {
      type: Boolean,
      default: false,
    },
    showActionsButton: {
      type: Boolean,
      default: false,
    },
    // * highlight text by seracher
    highlightText: {
      type: Boolean,
      default: false,
    },
    hidePrices: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      columns: [
        {
          field: 'Tag',
          label: 'Tag',
          hidden: !this.$ability.can('read', 'Piece_tag'),
        },
        {
          field: 'NoSeries',
          label: 'No. serie',
          hidden: !this.$ability.can('read', 'Piece_serie'),
        },
        {
          field: 'NoPetition',
          label: 'No. Pedimento',
          hidden: !this.$ability.can('read', 'Piece_petition'),
        },
        {
          field: 'NoInvoice',
          label: 'No. factura',
          hidden: !this.showInvoiceColumn
            || !this.$ability.can('read', 'Piece_invoice'),
        },
        {
          field: 'NoImport',
          label: 'No. importación',
          hidden: !this.$ability.can('read', 'Piece_import'),
        },
        {
          field: 'Condition',
          label: 'Condición',
          hidden: !this.$ability.can('read', 'Piece_condition'),
        },
        {
          field: 'Availability',
          label: 'Disponibilidad',
          hidden: !this.$ability.can('read', 'Piece_availability'),
        },
        {
          field: 'Location',
          label: 'Ubicación',
          hidden: !this.$ability.can('read', 'Piece_location'),
        },
        {
          field: 'ArriveDate',
          label: 'Fecha de llegada',
          hidden: !this.$ability.can('read', 'Piece_arrival_date'),
        },
        {
          field: 'UnitPriceInvoiceUsd',
          label: 'Precio unitario factura USD',
          hidden: this.hidePrices,
        },
        {
          field: 'Tc',
          label: 'Tipo de cambio',
          hidden: this.hidePrices,
        },
        {
          field: 'UnitPriceInvoiceMn',
          label: 'Precio unitario factura M/N',
          hidden: this.hidePrices,
        },
        {
          field: 'Ccost',
          label: 'Costeo MN\'SC / Utilidad(5%) M/N',
          hidden: this.hidePrices,
        },
        {
          field: 'ProvidersPriceUsd',
          label: 'Precio proveedores USD',
          hidden: this.hidePrices,
        },
        {
          field: 'ProvidersCostingMn',
          label: 'Costeo proveedores M/N',
          hidden: this.hidePrices,
        },
        {
          field: 'actions',
          label: 'Acciones',
          hidden: !this.showActionsButton,
        },
      ],
      piecesInCircuit: [],
      piecesList: this.pieces,
      provitionalPieces: [],
    }
  },
  computed: {
    ...mapGetters({
      getSearchTerms: 'filters/getSearchTerms',
      getQuotePieces: 'quotes/getQuotePieces',
      getLoadedPieces: 'circuits/getLoadedPieces',
      getCircuitPieces: 'circuits/getCircuitPieces',
      getDeletedPieces: 'circuits/getDeletedPieces',
      getTransferPieces: 'transfers/getTransferPieces',
      getCircuitProducts: 'circuits/getCircuitProducts',
      getDeletedProducts: 'circuits/getDeletedProducts',
      getProvitionalPieces: 'circuits/getProvitionalPieces',
      getProvitionalProducts: 'circuits/getProvitionalProducts',
    }),
    provitionalProducts: {
      get() { return this.getProvitionalProducts },
      set(value) { this.setProvitionalProducts(value) },
    },
    provitionalPiecesList: {
      get() { return this.getProvitionalPieces },
    },
    circuitProducts: {
      get() { return this.getCircuitProducts },
    },
    deletedProducts: {
      get() { return this.getDeletedProducts },
    },
    circuitPieces: {
      get() { return this.getCircuitPieces },
    },
    deletedPieces: {
      get() { return this.getDeletedPieces },
    },
    loadedPieces: {
      get() { return this.getLoadedPieces },
    },
    quotePieces: {
      get() { return this.getQuotePieces },
    },
    transferPieces: {
      get() { return this.getTransferPieces },
    },
    searchTerms: {
      get() { return this.getSearchTerms },
    },
    filteredFields() {
      if (this.showInvoiceColumn) {
        return this.fields
      }

      return this.fields.filter(field => field.key !== 'NoInvoice')
    },
    circuitProduct() {
      return this.circuitProducts.find(p => p.IdProduct === this.product.IdProduct)
    },
    deletedProduct() {
      return this.deletedProducts.find(p => p.IdProduct === this.product.IdProduct)
    },
    canHighlight() {
      return this.highlightText ? this.searchTerms : []
    },
  },
  watch: {
    circuitProducts(products) {
      const circuitProduct = products.find(p => p.IdProduct === this.product.IdProduct)
      if (circuitProduct) {
        this.piecesInCircuit = circuitProduct.pieces

        if (this.loadCircuitProducts) {
          this.piecesList = circuitProduct.pieces
        }
      } else {
        this.piecesInCircuit = []
      }
    },
    deletedProducts() {
      this.provitionalPieces.forEach(piece => {
        this.changePieceSelectedState(piece)
      })

      this.$refs['pieces-table'].selectedRows.forEach(piece => {
        this.changePieceSelectedState(piece)
      })
    },
    deletedPieces() {
      if (!this.loadCircuitProducts) {
        this.deletedPieces.forEach(deletedPiece => {
          if (deletedPiece.FK_IdProduct === this.product.IdProduct) {
            const isInProductPiecesList = productPiece => productPiece.IdPiece === deletedPiece.IdPiece
            const inProductPiecesList = this.product.pieces.some(isInProductPiecesList)

            let i = true

            if (this.provitionalPieces.length > 0) {
              i = this.provitionalPieces.some(isInProductPiecesList)
            }

            if (!inProductPiecesList && i) {
              this.product.pieces = [...this.product.pieces, deletedPiece]
              this.product.Qty = this.product.pieces.length
              this.piecesList = [...this.piecesList, deletedPiece]
            }
          }
        })
      }
    },
  },
  updated() {
    this.$nextTick(() => {
      this.$emit('rendering', false)
    })
  },
  created() {
    if (this.circuitProduct && !this.loadCircuitProducts) {
      this.product.pieces.forEach(productPiece => {
        const existsAtIndex = this.circuitProduct.pieces.findIndex(circuitPiece => circuitPiece.IdPiece === productPiece.IdPiece)
        if (existsAtIndex !== -1) {
          this.$set(productPiece, 'vgtSelected', true)
        }
      })

      this.deletedPieces.forEach(deletedPiece => {
        if (deletedPiece.FK_IdProduct === this.product.IdProduct) {
          const existsAtIndex = this.loadedPieces.findIndex(loadedPiece => loadedPiece.IdPiece === deletedPiece.IdPiece)

          if (existsAtIndex !== -1) {
            this.product.pieces = [...this.product.pieces, deletedPiece]
            this.piecesList = [...this.piecesList, deletedPiece]
          }
        }
      })
    }

    if (this.$route.name === 'new-quote' || this.$route.name === 'update-quote') {
      if (this.product.pieces) {
        this.product.pieces.forEach(productPiece => {
          const isInQuotePiecesList = quotePiece => quotePiece.IdPiece === productPiece.IdPiece
          const inQuotePiecesList = this.quotePieces.some(isInQuotePiecesList)
          const inQuotePiecesProvitionalList = this.provitionalPiecesList.some(isInQuotePiecesList)

          if (inQuotePiecesList || inQuotePiecesProvitionalList) {
            this.$set(productPiece, 'vgtSelected', true)
          }
        })
      }
    }

    if (this.$route.name === 'new-transfer' || this.$route.name === 'transfer-details') {
      if (this.product.pieces) {
        this.product.pieces.forEach(productPiece => {
          const isInTransferPiecesList = transferPiece => transferPiece.IdPiece === productPiece.IdPiece
          const inTransferPiecesList = this.transferPieces.some(isInTransferPiecesList)
          const inTransferProvitionalPiecesList = this.provitionalPiecesList.some(isInTransferPiecesList)

          if (inTransferPiecesList || inTransferProvitionalPiecesList) {
            this.$set(productPiece, 'vgtSelected', true)
          }
        })
      }
    }
  },
  methods: {
    ...mapActions({
      addCircuitProduct: 'circuits/addCircuitProduct',
      addDeletedProduct: 'circuits/addDeletedProduct',
      addProvitionalProduct: 'circuits/addProvitionalProduct',
      setProvitionalProducts: 'circuits/setProvitionalProducts',
      addProvitionalProductToDelete: 'circuits/addProvitionalProductToDelete',
    }),
    getAvailabilityStyle(availability) {
      switch (availability) {
        case 'Disponible':
          return 'light-success'
        case 'No disponible':
          return 'light-danger'
        default:
          return 'light-primary'
      }
    },
    selectionChanged(params) {
      const provitionalProduct = { ...this.product }
      provitionalProduct.pieces = params.selectedRows.map(piece => ({ ...piece }))
      this.provitionalPieces = [...this.provitionalPieces, ...params.selectedRows]

      if (this.loadCircuitProducts) {
        this.addProvitionalProductToDelete(provitionalProduct)
      } else {
        for (let index = 0; index < provitionalProduct.pieces.length; index += 1) {
          provitionalProduct.pieces[index].vgtSelected = false
        }

        this.addProvitionalProduct(provitionalProduct)
      }
    },
    addPiece(piece) {
      if (piece.Transfer === 'Si') {
        this.showToast(
          'Error de validación',
          'La pieza seleccionada se encuentra en una orden de traspaso.',
          'warning',
        )
        return
      }

      const isSameLocation = circuitPiece => circuitPiece.Location === piece.Location

      let sameCircuitLocation = true

      if (this.circuitPieces.length > 0) {
        sameCircuitLocation = this.circuitPieces.every(isSameLocation)
      }

      let sameLocationLoadedList = true

      const circuitPiecesAdded = []

      /**
       * Se agrega el llenado de circuitPiecesAdded para validar que efectivamente, lo que
       * se encuentra agregado al circuito, sea considerado para la validacion de ubicaciones.
       */
      this.circuitProducts.forEach(product => {
        product.pieces.forEach(productPiece => {
          circuitPiecesAdded.push(productPiece)
        })
      })

      /**
       * Se valida que existan productos en el circuito y que efectivamente existan piezas dentro
       * de los circuitos
       */
      if (circuitPiecesAdded.length > 0 && this.circuitProducts.length > 0) {
        sameLocationLoadedList = circuitPiecesAdded.every(isSameLocation)
      }

      if (sameCircuitLocation && sameLocationLoadedList) {
        this.provitionalPieces.push(piece)

        if (this.circuitProduct) {
          const selectedPiece = piece
          selectedPiece.vgtSelected = true

          const provitionalPiece = { ...selectedPiece }
          provitionalPiece.vgtSelected = false

          this.circuitProduct.pieces = [...this.circuitProduct.pieces, provitionalPiece]
          this.circuitProduct.Qty = this.circuitProduct.pieces.length
          this.addCircuitProduct(this.circuitProduct)

          this.showToast(
            'Pieza en circuito',
            'La pieza seleccionada se ha agregado al circuito.',
            'success',
          )
        } else {
          const selectedPiece = piece
          selectedPiece.vgtSelected = true

          const circuitProduct = { ...this.product }
          const provitionalPiece = { ...selectedPiece }

          provitionalPiece.vgtSelected = false
          circuitProduct.pieces = [provitionalPiece]

          circuitProduct.Qty = circuitProduct.pieces.length
          this.addCircuitProduct(circuitProduct)

          this.showToast(
            'Pieza en circuito',
            'La pieza seleccionada se ha agregado al circuito.',
            'success',
          )
        }
      } else {
        this.showToast(
          'Ubición incorrecta',
          'La pieza seleccionada no pertenece a la misma ubicación.',
          'warning',
        )
      }
    },
    deletePiece(piece) {
      const circuitProduct = this.circuitProducts.find(p => p.IdProduct === piece.FK_IdProduct)

      if (circuitProduct) {
        const selectedPiece = piece
        selectedPiece.vgtSelected = false

        const filteredPieces = circuitProduct.pieces.filter(circuitPiece => circuitPiece.IdPiece !== piece.IdPiece)
        circuitProduct.pieces = filteredPieces.map(filteredPiece => ({ ...filteredPiece }))
        circuitProduct.Qty = circuitProduct.pieces.length

        this.addCircuitProduct(circuitProduct)

        if (this.$route.name === 'new-circuit') {
          this.addDeletedProduct(circuitProduct)
        }

        if (this.$route.name === 'update-circuit' && !this.product.detailCircuit) {
          this.addDeletedProduct(circuitProduct)
        }

        this.showToast(
          'Pieza eliminada del circuito',
          'La pieza seleccionada se ha eliminado del circuito.',
          'success',
        )
      }

      if (this.product.detailCircuit) {
        const deletedProduct = this.deletedProducts.find(p => p.IdProduct === piece.FK_IdProduct)

        if (deletedProduct) {
          const selectedPiece = piece
          const provitionalPiece = { ...selectedPiece }

          this.deletedProduct.pieces = [...this.deletedProduct.pieces, provitionalPiece]
          this.addDeletedProduct(this.deletedProduct)
        } else {
          const selectedPiece = piece

          const newDeletedProduct = { ...this.product }
          const provitionalPiece = { ...selectedPiece }

          newDeletedProduct.pieces = [provitionalPiece]

          this.addDeletedProduct(newDeletedProduct)
        }
      }
    },
    pieceInCircuit(piece) {
      let existsAtIndex = -1

      if (this.loadCircuitProducts) {
        existsAtIndex = this.piecesInCircuit.findIndex(p => p.IdPiece === piece.IdPiece)
      } else {
        existsAtIndex = this.circuitPieces.findIndex(p => p.IdPiece === piece.IdPiece)
      }

      if (existsAtIndex === -1) {
        return false
      }

      return true
    },
    changePieceSelectedState(piece) {
      if (this.circuitPieces.length === 0) {
        this.$set(piece, 'vgtSelected', false)
        return
      }

      const isPieceInCircuit = circuitPiece => circuitPiece.IdPiece === piece.IdPiece
      const pieceInCircuit = this.circuitPieces.some(isPieceInCircuit)

      if (!pieceInCircuit) {
        this.$set(piece, 'vgtSelected', false)
      } else {
        this.$set(piece, 'vgtSelected', true)
      }
    },
    selectAllRows() {
      this.$refs['pieces-table'].toggleSelectAll()
    },
    canShowTransferTag(piece) {
      const routes = ['new-quote', 'update-quote',
        'order-details', 'products-home', 'new-transfer', 'new-circuit', 'update-circuit', 'transfer-details']
      return piece.Transfer === 'Si'
        && routes.includes(this.$route.name)
    },
    showToast(title, text, variant) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title, text, variant, icon: 'BellIcon',
        },
      })
    },
  },
}
</script>
